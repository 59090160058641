import { useCallback, useEffect, useRef, useState } from 'react'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { ProfileQueueItem, ProfileVariant } from '../../models/Profile'
import { initSearchQueue } from '../../clients/QueueClient'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { ProfileContext } from './ProfilePage'
import { ProfilesStack } from '../../ui/ProfilesStack/ProfilesStack'
import styles from './ProfileSearchResults.module.sass'
import { useLatestRef } from '../../hooks/useLatestRef'
import { InputFormField } from '../../ui/Input'
import { FormButtonContextProvider } from '../../ui/FormButtonContext'
import { MainButtonContext } from '../../ui/MainButtonContext'
import { Button, Tooltip } from '@mui/joy'
import { Edit } from '@mui/icons-material'
import logo from '../../logo-wide.png'
import { LogoBlackIcon } from '../../icons/LogoIcon'

export const ProfileSearchResultsPage = () => {
  const { profile, refreshProfile } = useSafeContext(ProfileContext)
  const [triedSearch, setTriedSearch] = useState<boolean>(!!profile.triedSearch)
  const [tooltipOpen, setTooltipOpen] = useState(triedSearch)

  useEffect(() => {
    if (triedSearch) {
      setTimeout(() => {
        setTooltipOpen(false)
      }, 3000)
    }
  }, [triedSearch, setTooltipOpen])

  const [results, setResults] = useState<ProfileQueueItem[] | null>()

  const [query, setQuery] = useState(profile.searchQuery ?? profile.position)

  const refetch = useCallback(() => {
    setResults(undefined)
  }, [setResults])

  const queryRef = useLatestRef(query)

  const { show, hide } = useSafeContext(MainButtonContext)

  useEffect(() => {
    if (queryRef.current && results !== undefined) {
      return
    }

    if (!triedSearch) {
      return
    }

    initSearchQueue(profile.id, queryRef.current)
      .then(({ data }) => {
        if (!data.length) {
          return setResults(null)
        }
        setResults(data)
        if (!triedSearch) {
          setTriedSearch(true)
        }
      })
      .catch(() => setResults(null))
  }, [profile.id, results, queryRef, triedSearch, setTriedSearch])

  const initQueryRef = useRef(query)

  const hasError = query.trim().length < 2 || query.trim().length > 200

  const [isProfileOpen, setProfileOpen] = useState(false)

  return (
    <>
      <PageLayout className={styles.mainWrapper} title={`${profile.position ?? 'Загрузка профиля...'} - Подборка`}>
        {!triedSearch ? (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <FormButtonContextProvider removeHandler={show} resetToDefault={hide}>
              <div style={{ flex: 1 }}>
                <div>
                  <div
                    style={{
                      width: '100vw',
                      height: '42px',
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      textAlign: 'center',
                      background: '#fff',
                      padding: '8px 10px',
                      boxSizing: 'content-box',
                      boxShadow: '0 4px 6px rgba(187, 187, 187, 0.1)',
                    }}
                  >
                    <img style={{ maxHeight: '100%' }} src={logo} alt="Relevants" />
                  </div>
                  <InputFormField
                    size="lg"
                    onClick={() => {
                      initQueryRef.current = query
                    }}
                    placeholder="Ваш запрос"
                    label={`Поиск ${profile.variant === ProfileVariant.Job ? 'кандидатов' : 'вакансий'} по запросу:`}
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    defaultValue={query}
                    color={hasError ? 'danger' : 'neutral'}
                    onOpenChange={open => {
                      if (!hasError && !open && initQueryRef.current !== query) {
                        setResults(undefined)
                        setTriedSearch(true)
                        refreshProfile()
                      }
                    }}
                    variant="outlined"
                    error={hasError}
                    errors={hasError ? ['Запрос должен быть от 2 до 200 символов'] : []}
                    endDecorator={<Edit fontSize="small" color="secondary" />}
                    helperText={
                      <div>
                        Вы можете включить сюда название должности и навыки.{' '}
                        <div>
                          <b>Например: UI/UX Дизайнер Figma, Photoshop</b>
                        </div>
                      </div>
                    }
                  />
                  <Button
                    disabled={hasError}
                    size="lg"
                    fullWidth
                    variant="soft"
                    sx={{ marginTop: '12px' }}
                    onClick={() => {
                      setResults(undefined)
                      setTriedSearch(true)
                      refreshProfile()
                    }}
                  >
                    Искать
                  </Button>
                </div>
              </div>
            </FormButtonContextProvider>
          </div>
        ) : (
          <>
            {!isProfileOpen && (
              <div
                style={{
                  width: '100vw',
                  height: '58px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  background: '#fff',
                  padding: '8px 16px',
                  boxShadow: '0 4px 6px rgba(187, 187, 187, 0.1)',
                  marginTop: '-24px',
                  marginLeft: '-16px',
                  justifyContent: 'space-between',
                  gap: '16px',
                }}
              >
                <div style={{ width: '42px', height: '42px' }}>
                  <LogoBlackIcon style={{ height: '100%', width: '100%' }} />
                </div>
                <div style={{ width: 'calc(100% - 56px)' }}>
                  <FormButtonContextProvider removeHandler={show} resetToDefault={hide}>
                    <InputFormField
                      onClick={() => {
                        initQueryRef.current = query
                      }}
                      startDecorator={profile.variant === ProfileVariant.Job ? 'Резюме:' : 'Вакансии:'}
                      placeholder="Ваш запрос"
                      label={`Поиск ${profile.variant === ProfileVariant.Job ? 'кандидатов' : 'вакансий'}`}
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                      defaultValue={query}
                      color={hasError ? 'danger' : 'primary'}
                      onOpenChange={open => {
                        if (!hasError && !open && initQueryRef.current !== query) {
                          setResults(undefined)
                        }
                      }}
                      error={hasError}
                      errors={hasError ? ['Запрос должен быть от 2 до 200 символов'] : []}
                      labelOnlyWhenOpen
                      endDecorator={
                        <Tooltip title="Измените запрос в любой момент" placement="bottom" arrow open={tooltipOpen}>
                          <Edit fontSize="small" color="secondary" />
                        </Tooltip>
                      }
                    />
                  </FormButtonContextProvider>
                </div>
              </div>
            )}
            <ProfilesStack
              setProfileOpen={setProfileOpen}
              isProfileOpen={isProfileOpen}
              profiles={results}
              refetch={refetch}
            />
          </>
        )}
      </PageLayout>
    </>
  )
}
