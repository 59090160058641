import { ProfileContext } from './ProfilePage'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { useEffect, useState } from 'react'
import { getProfileDetails, getPublicProfile } from '../../clients/ProfileClient'
import { Link, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { ProfileQueueItem } from '../../models/Profile'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'
import styles from './ProfileViewPage.module.sass'
import { Alert, Button, IconButton, LinearProgress } from '@mui/joy'
import { profileToString } from '../../tools/StringUtil'
import similarity from 'similarity'
import { useAppState } from '../../ui/AppState'
import { Edit } from '@mui/icons-material'
import { WaveIcon } from '../../icons/WaveIcon'
import { QueueSwipeResult, swipeProfile } from '../../clients/QueueClient'
import { useToast } from '../../ui/Toast/Toast'

export const ProfileViewPage = ({ isPublic }: { isPublic?: boolean }) => {
  const { profile } = useSafeContext(ProfileContext)
  const { targetId } = useParams()
  const [targetProfile, setTargetProfile] = useState<ProfileQueueItem | null>()
  const [sp] = useSearchParams()
  const isInitial = !!sp.get('start')
  const { user } = useAppState()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState<boolean | 'never'>(false)
  const { showToast } = useToast()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (targetId) {
      ;(isPublic ? getPublicProfile(targetId) : getProfileDetails(profile.id, +targetId))
        .then(({ data }) => setTargetProfile(data as ProfileQueueItem))
        .catch(() => setTargetProfile(null))
    }
  }, [profile.id, targetId, isPublic])

  return (
    <PageLayout bottomMenu topMenu title={`${targetProfile?.position ?? 'Загрузка профиля...'}`}>
      <TopBackLink to={isInitial ? `/profile/${profile.id}` : undefined} />
      {targetProfile === undefined && <LinearProgress />}
      {targetProfile?.userId === user.id && !profile.isArchived && (
        <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: '12px' }}>
          <IconButton
            variant="solid"
            color="primary"
            size="sm"
            onClick={() => navigate(`/profile/${profile.id}/edit/`)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </div>
      )}
      {targetProfile && (
        <>
          <ProfileDetails
            match={
              profile.id !== targetProfile.id
                ? +(similarity(profileToString(profile), profileToString(targetProfile)) * 100).toFixed(0)
                : undefined
            }
            currentProfile={profile}
            profile={targetProfile}
            hideReport={isPublic}
          />
          {isPublic && profile.isFull === false && (
            <Alert style={{ marginTop: '12px', fontSize: '14px' }} color="warning">
              <div>
                Ваш профиль неполный. Необходимо заполнить информацию о себе и что ищете.{' '}
                <Link to={`/profile/${profile.id}/edit`}>Редактировать</Link>
              </div>
            </Alert>
          )}
          {isPublic && processing !== 'never' && targetProfile.userId !== user.id && (
            <Button
              fullWidth
              variant="soft"
              disabled={!profile.isFull}
              onClick={() => {
                setProcessing(true)
                swipeProfile(profile.id, targetProfile.id, QueueSwipeResult.LIKE, targetId)
                  .then(() => {
                    showToast({
                      title: 'Отклик отправлен!',
                      description: 'Пользователь получит уведомление о вашем отклике.',
                      variant: 'message',
                    })
                    sessionStorage.removeItem('startPayload')
                    setSearchParams({}, { replace: true })
                    navigate('/profile/' + profile.id + '/search')
                  })
                  .catch(() =>
                    showToast({
                      title: 'Ошибка',
                      description: 'Что-то пошло не так. Попробуйте еще.',
                      variant: 'error',
                    }),
                  )
                  .finally(() => setProcessing('never'))
              }}
              style={{ marginTop: 12 }}
              loading={processing}
            >
              <WaveIcon style={{ width: 28, height: 28 }} /> Откликнуться
            </Button>
          )}
        </>
      )}
      {targetProfile === null && (
        <div className={styles.wrapper}>
          Профиль не найден или был удален. <NavLink to={`/profile/${profile.id}/search`}>Подборка</NavLink>
        </div>
      )}
    </PageLayout>
  )
}
