import { useCallback, useEffect, useState } from 'react'
import { ProfileBase } from '../../models/Profile'
import { useForm } from '../../ui/hooks/useForm'
import Joi from '@hapi/joi'
import { getKey } from '../../tools/LocalStorageUtil'

const commonValidators: Partial<Record<keyof ProfileBase, any>> = {
  position: Joi.string().trim().min(2).max(128).required(),
  location: Joi.string().trim().min(2).max(64).optional().allow('', null),
  variant: Joi.any(),
  description: Joi.string().trim().min(10).max(700).allow('', null),
  lookingForDescription: Joi.string().trim().min(10).max(700).allow('', null),
  financialPeriod: Joi.any(),
  financialMin: Joi.number().min(0).max(100000000).allow('', null),
  financialMax: Joi.number().min(0).max(100000000).allow('', null),
  workType: Joi.any(),
  extraInfo: Joi.string().trim().min(5).max(700).allow('', null),
  profilePicture: Joi.string().trim().min(10).max(512).allow('', null),
  experience: Joi.number().min(0).max(30).required(),
  portfolioLink: Joi.string()
    .trim()
    .min(5)
    .max(512)
    .allow('', null)
    .pattern(/^(https?:\/\/)?([\w\d-]+\.)+[\w\d-]+(\/[\w\d-./?%&=]*)?$/),
}

export const useCreateProfileState = ({
  currentStep,
  setCurrentStep,
  initialProfile,
  isPersistent,
}: {
  currentStep?: number
  setCurrentStep: (newStep: number | undefined) => void
  initialProfile?: ProfileBase
  isPersistent?: boolean
}) => {
  const [validationSchema, setValidationSchema] = useState<Partial<Record<keyof ProfileBase, any>>>()

  const { errors, onChange, state, setState } = useForm<ProfileBase>({
    validationSchema,
    initialArgs: initialProfile,
  })

  const [isInitialized, setInitialized] = useState(false)

  const resetProfile = useCallback(() => {
    localStorage.removeItem(getKey('profile-creation-state'))
    localStorage.removeItem(getKey('profile-creation-step'))
    setCurrentStep(undefined)
    setState({})
  }, [setCurrentStep, setState])

  useEffect(() => {
    setValidationSchema(commonValidators)
  }, [state.variant])

  useEffect(() => {
    const init = () => {
      if (!state.variant) {
        const localState = JSON.parse(localStorage.getItem(getKey('profile-creation-state')) ?? '{}') as ProfileBase
        if (localState.variant) {
          if (currentStep !== undefined) {
            localStorage.setItem(getKey('profile-creation-step'), String(currentStep))
          }

          const localCurrentStep = localStorage.getItem(getKey('profile-creation-step'))
          setCurrentStep(localCurrentStep ? +localCurrentStep : currentStep === undefined ? 0 : currentStep)

          return setState(localState)
        }
      }

      if (state.variant) {
        localStorage.setItem(getKey('profile-creation-step'), String(currentStep))
        return localStorage.setItem(getKey('profile-creation-state'), JSON.stringify({ ...state }))
      }
    }

    if (currentStep !== -1 && isPersistent) {
      init()
    }

    setInitialized(true)
  }, [state, setState, currentStep, setCurrentStep, isPersistent])

  return {
    profile: state,
    onChange,
    isInitialized,
    errors,
    setProfile: setState,
    resetProfile,
  }
}
