import { Modal, ModalDialog, Typography } from '@mui/joy'
import { useCreateProfileState } from './useCreateProfileState'
import { Button } from '../../ui/Button/Button'
import { useEffect, useMemo, useState } from 'react'
import { createProfileSteps } from './createProfileSteps'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { MainButtonContext } from '../../ui/MainButtonContext'
import { FormButtonContextProvider } from '../../ui/FormButtonContext'
import { CreateProfileContext, CreateProfileContextState } from './CreateProfilePage'
import { ProfileContext } from '../ProfilePage/ProfilePage'
import _ from 'lodash'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'
import { editProfile } from '../../clients/ProfileClient'
import { useToast } from '../../ui/Toast/Toast'

export const EditProfilePage = () => {
  const [isLoading, setLoading] = useState(false)

  const [currentStep, setCurrentStep] = useState<number>()

  const {
    profile: {
      id,
      createdAt,
      isArchived,
      archivedAt,
      incomingLikesCount,
      isParsed,
      nickname,
      categoryId,
      isFull,
      searchQuery,
      triedSearch,
      ...currentProfile
    },
    refreshProfile,
  } = useSafeContext(ProfileContext)

  const { show, hide, enable } = useSafeContext(MainButtonContext)

  const { errors, profile, onChange, resetProfile } = useCreateProfileState({
    currentStep,
    setCurrentStep,
    initialProfile: currentProfile,
  })

  console.log(errors)

  const targetSteps = useMemo(
    () => [createProfileSteps(profile.variant?.toLowerCase() ?? ('job' as any)).at(-1)!],
    [profile.variant],
  )

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

  useEffect(() => {
    document.body.style.background = '#f7f7f8'
  }, [])

  const contextValue: CreateProfileContextState = {
    errors,
    goToStep: setCurrentStep,
    next: _.noop,
    back: _.noop,
    onChange,
    resetProfile,
    state: profile,
    onClose: () => setConfirmDialogOpen(true),
    isEditPage: true,
  }

  useEffect(() => {
    return () => {
      enable()
      hide()
    }
  }, [enable, hide])

  const Component = targetSteps[0]

  const { showToast } = useToast()

  return (
    <FormButtonContextProvider removeHandler={show} resetToDefault={hide}>
      <CreateProfileContext.Provider value={contextValue}>
        <TopBackLink />
        <div
          style={{
            boxSizing: 'border-box',
            padding: '79px 16px 116px 16px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <div style={{ width: '100%' }}>
            <Component />
          </div>
          <Button
            disabled={Object.values(errors ?? {}).length > 0}
            size="lg"
            variant="solid"
            sx={{ width: '100%' }}
            onClick={() => {
              setLoading(true)
              editProfile({
                ...profile,
                id,
                description: profile.description || undefined,
                lookingForDescription: profile.lookingForDescription || undefined,
                extraInfo: profile.extraInfo || undefined,
              })
                .then(() => {
                  refreshProfile()
                  showToast({ variant: 'message', title: 'Сохранено', description: 'Мы обновили ваш профиль' })
                })
                .catch(e => {
                  showToast({ variant: 'error', title: 'Ошибка', description: e?.response?.data })
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
            loading={isLoading}
          >
            Сохранить
          </Button>
        </div>
        {isConfirmDialogOpen && (
          <Modal open={isConfirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Typography level="h4">Вы уверены?</Typography>
                <Typography textColor="text.tertiary">
                  Вы действительно хотите отменить редактирование? Это удалит все введенные данные.
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={() => setConfirmDialogOpen(false)} color="neutral" variant="plain">
                    Отмена
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      resetProfile()
                      setConfirmDialogOpen(false)
                    }}
                  >
                    Удалить
                  </Button>
                </div>
              </div>
            </ModalDialog>
          </Modal>
        )}
      </CreateProfileContext.Provider>
    </FormButtonContextProvider>
  )
}
