import { PageLayout } from '../../ui/PageLayout/PageLayout'
import styles from './ErrorPage.module.sass'
import logo from '../../logo-wide.png'
import { Card } from '../../ui/Card/Card'
export const ErrorPage = ({ writeAccess }: { writeAccess?: boolean }) => {
  if (writeAccess) {
    return (
      <>
        <div className={styles.headerWrapper}>
          <div className={styles.innerWrapper}>
            <img src={logo} alt="Relevants" onClick={() => (document.location.href = 'https://relevants.space')} />
          </div>
        </div>
        <PageLayout>
          <div className={styles.contentWrapper}>
            <Card>
              <div className={styles.errorWrapper}>
                <div>Для корректной работы нашего приложения Вам необходимо разрешить сообщения от нашего бота.</div>
                <div>Мы не будем присылать ничего лишего.</div>
                <div>
                  Последняя информация о нашем приложении доступна в нашем канале в Telegram:{' '}
                  <a href="https://t.me/relevants_app">@relevants_app</a>.
                </div>
                <div>
                  Нужна помощь? Свяжитесь с нашей поддержкой: <a href="https://t.me/rele_support">@rele_support</a>.
                </div>
              </div>
            </Card>
          </div>
        </PageLayout>
      </>
    )
  }

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.innerWrapper}>
          <img src={logo} alt="Relevants" onClick={() => (document.location.href = 'https://relevants.space')} />
        </div>
      </div>
      <PageLayout>
        <div className={styles.contentWrapper}>
          <Card>
            <div className={styles.errorWrapper}>
              <div>Вероятно, мы проводим технические работы. Перезагрузите бот или зайдите позже.</div>
              <div>
                Последняя информация о нашем приложении доступна в нашем канале в Telegram:{' '}
                <a href="https://t.me/relevants_app">@relevants_app</a>.
              </div>
              <div>
                Нужна помощь? Свяжитесь с нашей поддержкой: <a href="https://t.me/rele_support">@rele_support</a>.
              </div>
            </div>
          </Card>
        </div>
      </PageLayout>
    </>
  )
}
