import {
  AvatarGroup,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  LinearProgress,
  Avatar as MUIAvatar,
  Modal,
  ModalDialog,
  Switch,
  Typography,
} from '@mui/joy'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { StatsSlider } from '../../ui/StatsSlider/StatsSlider'
import styles from './TitleProfilePage.module.sass'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { ProfileContext } from './ProfilePage'
import { plural } from '../../tools/StringUtil'
import { useNavigate } from 'react-router-dom'
import config from '../../config'
import { useCallback, useEffect, useState } from 'react'
import { getPublicProfile, markAsArchived, togglePublicProfile } from '../../clients/ProfileClient'
import { TrashBinIcon } from '../../icons/TrashBinIcon'
import { Avatar } from '../../ui/Avatar/Avatar'
import { ProfileVariant } from '../../models/Profile'
import { ContentCopy } from '@mui/icons-material'
import { useToast } from '../../ui/Toast/Toast'

export const TitleProfilePage = () => {
  const { profile, incomingLikes, refreshProfile } = useSafeContext(ProfileContext)

  const navigate = useNavigate()

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const surplus = incomingLikes && incomingLikes.length > 3 ? incomingLikes.length - 3 : 0

  const [isProcessing, setProcessing] = useState(false)

  const archiveProfile = useCallback(async () => {
    setConfirmDialogOpen(false)
    setProcessing(true)
    markAsArchived(profile.id)
      .then(refreshProfile)
      .catch(e => console.log(e))
      .finally(() => setProcessing(false))
  }, [profile.id, refreshProfile])

  const [nickname, setNickname] = useState<string | null>()
  const [refetchCounter, setRefecthCounter] = useState(0)

  useEffect(() => {
    if (!profile.nickname) {
      setNickname(null)
      return
    }

    getPublicProfile(profile.nickname)
      .then(() => {
        setNickname(profile.nickname)
      })
      .catch(e => setNickname(null))
  }, [profile.nickname, setNickname, refetchCounter])

  const { showToast } = useToast()

  if (!incomingLikes) {
    return null
  }

  return (
    <>
      <div className={styles.profileWrapper}>
        <div>
          <Avatar size={48} profilePicture={profile.profilePicture} />
        </div>
        <div className={styles.profileDetails}>
          <span className={styles.positionName}>{profile.position}</span>
          <span className={styles.profileVariant}>
            {profile.variant === ProfileVariant.Job ? 'Вакансия' : 'Резюме'}
          </span>
        </div>
        <div className={styles.openButton}>
          <Chip
            size="sm"
            color="primary"
            variant="soft"
            onClick={() => navigate(`/profile/${profile.id}/view/${profile.id}`)}
            disabled={profile.isArchived}
          >
            Изменить
          </Chip>
        </div>
      </div>
      {!profile.isArchived && (
        <>
          <div className={styles.profileWrapper}>
            <div className={styles.publicInner}>
              <div className={styles.publicRow}>
                <div className={styles.profileDetails}>
                  <span className={styles.positionName}>Опубликовать для всех</span>
                </div>
                <div className={styles.openButton}>
                  <Switch
                    checked={!!nickname}
                    onChange={() => {
                      // setNickname(undefined)
                      togglePublicProfile(profile.id)
                        .then(data => {
                          refreshProfile()
                          setNickname(data.data.nickname)
                          setRefecthCounter(prev => prev + 1)
                        })
                        .catch(() => {
                          showToast({
                            description: 'Что-то пошло не так...',
                            variant: 'error',
                          })
                        })
                    }}
                  />
                </div>
              </div>
              {nickname === undefined && <LinearProgress />}
              {nickname && (
                <div className={styles.publicRow}>
                  <Input
                    id="nicknameInput"
                    readOnly
                    value={'https://relevants.co/' + nickname}
                    size="sm"
                    sx={{ flex: 1 }}
                  />
                  <IconButton
                    variant="soft"
                    size="sm"
                    color="primary"
                    onClick={() => {
                      ;(document.getElementById('nicknameInput') as any)?.select()
                      document.execCommand('copy')
                      showToast({
                        description: 'Ссылка скопирована. Вы можете поделиться ей с другими.',
                        variant: 'default',
                      })
                    }}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </div>
              )}
              {nickname === null && <Divider />}
              <div className={styles.publicRow}>
                <span style={{ fontSize: 12 }}>
                  Поделитесь ссылкой на профиль с другими. Это отличная альтернатива Taplink.
                </span>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 24, height: '40vh' }}>
            <StatsSlider profileId={profile.id} />
          </div>
        </>
      )}
      <PageLayout className={styles.pageLayout + (profile.isArchived ? ' ' + styles.fullHeight : '')}>
        <div className={styles.tiles}>
          {incomingLikes.length > 0 && !profile.isArchived && (
            <div className={styles.tile + ' ' + styles.incomingLikesTile}>
              <span>
                {incomingLikes.length} {plural(incomingLikes.length, ['человек ждут', 'человека ждут', 'человек ждет'])}{' '}
                отклика
              </span>
              <div className={styles.avatarsGroup}>
                <AvatarGroup>
                  {incomingLikes?.slice(0, 3).map((l, i) => (
                    <MUIAvatar src={config.API_URL + '/' + l.profilePicture} key={l.id + i} />
                  ))}
                  {!!surplus && <MUIAvatar>+{surplus}</MUIAvatar>}
                </AvatarGroup>
                <div className={styles.button}>
                  <Chip size="sm" color="primary" variant="soft" onClick={() => navigate('likes')}>
                    Смотреть
                  </Chip>
                </div>
              </div>
            </div>
          )}
          <div className={styles.tile + ' ' + styles.historyTile}>
            <span style={{ display: 'flex', alignItems: 'center' }}>История взаимных лайков</span>
            <Chip size="sm" color="primary" variant="soft" onClick={() => navigate('matches')}>
              Смотреть
            </Chip>
          </div>
        </div>
        {!profile.isArchived ? (
          <Button
            variant="outlined"
            color="neutral"
            fullWidth
            style={{ gap: 8, color: '#5A5A72' }}
            onClick={() => setConfirmDialogOpen(true)}
            loading={isProcessing}
            disabled={isProcessing}
          >
            <TrashBinIcon style={{ width: 24, height: 24 }} />
            Убрать профиль из поиска
          </Button>
        ) : (
          <span className={styles.archivedInfo}>Вы удалили этот профиль</span>
        )}
        {isConfirmDialogOpen && (
          <Modal open={isConfirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
            <ModalDialog variant="outlined" role="alertdialog">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Typography level="h4">Вы уверены?</Typography>
                <Typography textColor="text.tertiary">
                  Вы действительно хотите удалить профиль? Это действие нельзя отменить. Вы не будете показываться
                  другим пользователям в поиске.
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={() => setConfirmDialogOpen(false)} color="neutral" variant="plain">
                    Отмена
                  </Button>
                  <Button color="danger" onClick={archiveProfile}>
                    Удалить
                  </Button>
                </div>
              </div>
            </ModalDialog>
          </Modal>
        )}
      </PageLayout>
    </>
  )
}
