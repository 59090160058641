import { birthYearToAge, plural } from '../../tools/StringUtil'
import { Avatar } from '../Avatar/Avatar'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Caption } from '../Caption/Caption'
import {
  ProfileFinancialPeriod,
  ProfileVariant,
  ProfileWorkType,
  profileFinancialPeriodLabelsCompact,
} from '../../models/Profile'
import { Chip, IconButton } from '@mui/joy'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Edit, HelpOutline } from '@mui/icons-material'
import styles from './UserDetails.module.sass'
import { useToast } from '../Toast/Toast'

interface UserDetailsProps {
  personName: string
  birthYear: number
  title?: string
  location?: string
  match?: number
  financialMin?: number
  financialMax?: number
  profilePicture?: string
  workType?: ProfileWorkType[]
  financialPeriod?: ProfileFinancialPeriod
  hasLike?: boolean
  experience?: number
  variant: ProfileVariant
  onEdit?: (val: boolean) => void
  isParsed?: boolean
}
export const UserDetails = ({
  personName,
  birthYear,
  profilePicture,
  title,
  match,
  location,
  workType,
  financialMin,
  financialMax,
  financialPeriod,
  hasLike,
  experience,
  variant,
  onEdit,
  isParsed,
}: UserDetailsProps) => {
  const hasFinancialPeriod = !!(financialPeriod && (financialMin || financialMax))

  const { showToast } = useToast()

  return (
    <div className={styles.userDetailsWrapper}>
      {onEdit && (
        <div style={{ position: 'absolute', right: 16 }}>
          <IconButton variant="soft" color="neutral" size="sm" onClick={() => onEdit(true)}>
            <Edit fontSize="small" />
          </IconButton>
        </div>
      )}
      <div className={styles.detailsHeader}>
        <div>
          <Avatar profilePicture={profilePicture} progress={25} hasLike={hasLike} />
        </div>
        <div className={styles.profileInfo}>
          <Caption className={styles.profileTitle}>
            {variant === ProfileVariant.Job && 'Ищу: '}
            {title}
          </Caption>
          <Caption className={styles.profileBottomLineWrapper}>
            <span className={styles.personName}>
              {personName}
              {birthYear !== -1 && !isParsed && (
                <>
                  , {birthYearToAge(birthYear)} {plural(birthYearToAge(birthYear) ?? 0, ['год', 'года', 'лет'])}
                </>
              )}
            </span>
            {location && (
              <span className={styles.location}>
                <LocationOnOutlinedIcon className={styles.locationIcon} />
                {location}
              </span>
            )}
          </Caption>
        </div>
      </div>
      {(hasFinancialPeriod || !!match) && (
        <div className={styles.subheader}>
          {!!match && match > 0 && (
            <div className={styles.similarity + (hasFinancialPeriod ? '' : ' ' + styles.fullWidth)}>
              <div className={styles.inner}>
                <span className={styles.similarityLevel}>{match}%</span>
                <span className={styles.bottomLabel}>Совпадение</span>
              </div>
            </div>
          )}
          {hasFinancialPeriod && (
            <div className={styles.financialInfo}>
              <div className={styles.inner}>
                <div className={styles.price}>
                  {(financialMin ?? 0) > 0 && (
                    <>
                      от <span className={styles.primary}>{financialMin} руб</span>
                    </>
                  )}{' '}
                  {(financialMax ?? 0) > 0 && (
                    <>
                      до <span className={styles.primary}>{financialMax} руб</span>
                    </>
                  )}
                </div>
                <span className={styles.bottomLabel}>{profileFinancialPeriodLabelsCompact[financialPeriod]}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {(workType || experience !== undefined || isParsed !== undefined) && (
        <div className={styles.tags}>
          {isParsed && (
            <Chip color="warning" variant="soft" size="sm">
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                Внешний{' '}
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    showToast({
                      title: 'Внешний профиль',
                      description: `Профили с такой меткой были созданы автоматически на основе данных из каналов по поиску работы в Telegram. Мы не можем гарантировать их актуальность и достоверность. Если Вы лайкните этот профиль, то мы пришлем Вам контакты для связи автоматически.`,
                      variant: 'default',
                      duration: 10000,
                    })
                  }}
                >
                  <HelpOutline />
                </div>
              </div>
            </Chip>
          )}
          {experience !== undefined && (
            <Chip color="primary" variant="soft" size="sm">
              {experience === 0 && 'Без опыта'}
              {experience > 0 && (
                <>
                  {variant === ProfileVariant.Job ? 'От ' : experience === 10 ? 'Более ' : ' '}
                  {experience} {plural(experience, ['года', 'лет', 'лет'])} опыта
                </>
              )}
            </Chip>
          )}
          {workType?.includes(ProfileWorkType.FullTime) && (
            <Chip color="primary" variant="soft" size="sm">
              <div className={styles.workType}>
                <CalendarMonthOutlinedIcon style={{ width: 16, height: 16 }} />
                Full-time
              </div>
            </Chip>
          )}
          {workType?.includes(ProfileWorkType.PartTime) && (
            <Chip color="primary" variant="soft" size="sm">
              <div className={styles.workType}>
                <CalendarMonthOutlinedIcon style={{ width: 16, height: 16 }} />
                Part-time
              </div>
            </Chip>
          )}
          {/* {tags?.slice(0, 3).map(t => (
            <Chip variant="soft" color="primary" key={t} size="sm">
              {t.toUpperCase()}
            </Chip>
          ))}
          {tags?.length && tags.length > 3 && (
            <Chip variant="soft" size="sm" color="neutral">
              +{tags.length - 3}
            </Chip>
          )} */}
        </div>
      )}
    </div>
  )
}
