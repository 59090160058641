import { Badge, List, ListDivider, ListItem, ListItemDecorator } from '@mui/joy'
import { ProfileCompact, ProfileVariant } from '../../models/Profile'
import { Avatar } from '../Avatar/Avatar'
import styles from './ProfilesList.module.sass'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PlusOutlinedIcon } from '../../icons/PlusOutlinedIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { SettingsIcon } from '../../icons/SettingsIcon'
import { SupportIcon } from '../../icons/SupportIcon'
import { TickIcon } from '../../icons/TickIcon'
import { ShieldIcon } from '../../icons/ShieldIcon'
import { useAppState } from '../AppState'
interface ProfilesListProps {
  profiles: ProfileCompact[]
  onListItemClick?: (profile: ProfileCompact) => void
  onAddProfileClick?: () => void
}
export const ProfilesList = ({ profiles, onAddProfileClick, onListItemClick }: ProfilesListProps) => {
  const navigate = useNavigate()

  return (
    <>
      {profiles.length > 0 && (
        <List
          variant="outlined"
          sx={{
            bgcolor: 'background.body',
            minWidth: 240,
            borderRadius: '8px',
            boxShadow: '1px 2px 12px 0px rgba(187, 187, 187, 0.18)',
            border: 'none',
          }}
        >
          {profiles.map((p, i) => (
            <React.Fragment key={p.id}>
              <ListItem
                onClick={() => (onListItemClick ? onListItemClick(p) : navigate('/profile/' + p.id))}
                sx={{ height: 44 }}
              >
                <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
                  <Badge variant="soft" badgeContent={p.isArchived ? 0 : p.incomingLikesCount ?? 0} size="sm">
                    <Avatar profilePicture={p.profilePicture} size={32} />
                  </Badge>
                </ListItemDecorator>
                <span className={styles.profileListItemTile}>
                  {p.variant === ProfileVariant.Job ? `Ищу:` : ``} {p.position}
                  <ChevronRightIcon />
                </span>
              </ListItem>
              {i !== profiles.length - 1 && <ListDivider sx={{ background: '#EBEBEF' }} inset="startContent" />}
            </React.Fragment>
          ))}
        </List>
      )}
      <div
        className={styles.addWrapper}
        onClick={() => (onAddProfileClick ? onAddProfileClick() : navigate('/create-profile'))}
      >
        <PlusOutlinedIcon /> Добавить профиль
      </div>
    </>
  )
}

export const SettingsList = ({ isOpen }: { isOpen: boolean }) => {
  const navigate = useNavigate()
  const {
    user: { canSeeStats, canManageProfiles },
  } = useAppState()

  return (
    <List
      variant="outlined"
      sx={{
        bgcolor: 'background.body',
        minWidth: 240,
        borderRadius: '8px',
        boxShadow: '1px 2px 12px 0px rgba(187, 187, 187, 0.18)',
        border: 'none',
      }}
    >
      {!isOpen ? (
        <>
          <ListItem onClick={() => navigate('/settings')} sx={{ height: 44 }}>
            <ListItemDecorator>
              <SettingsIcon style={{ width: 24, height: 24 }} />
            </ListItemDecorator>
            <span className={styles.profileListItemTile}>
              Управление и поддержка
              <ChevronRightIcon />
            </span>
          </ListItem>
          {canManageProfiles && (
            <ListItem onClick={() => navigate('/admin')} sx={{ height: 44 }}>
              <ListItemDecorator>
                <SettingsIcon style={{ width: 24, height: 24 }} />
              </ListItemDecorator>
              <span className={styles.profileListItemTile}>
                Админ
                <ChevronRightIcon />
              </span>
            </ListItem>
          )}
          {canSeeStats && (
            <ListItem onClick={() => navigate('/settings')} sx={{ height: 44 }}>
              <ListItemDecorator>
                <SettingsIcon style={{ width: 24, height: 24 }} />
              </ListItemDecorator>
              <span className={styles.profileListItemTile}>
                Статистика
                <ChevronRightIcon />
              </span>
            </ListItem>
          )}
        </>
      ) : (
        <>
          <ListItem onClick={() => navigate('contact')} sx={{ height: 44 }}>
            <ListItemDecorator>
              <SupportIcon style={{ width: 24, height: 24 }} />
            </ListItemDecorator>
            <span className={styles.profileListItemTile}>
              Написать в поддержку
              <ChevronRightIcon />
            </span>
          </ListItem>
          <ListDivider sx={{ background: '#EBEBEF' }} inset="startContent" />
          <ListItem onClick={() => navigate('privacy')} sx={{ height: 44 }}>
            <ListItemDecorator>
              <ShieldIcon style={{ width: 24, height: 24 }} />
            </ListItemDecorator>
            <span className={styles.profileListItemTile}>
              Политика обработки данных
              <ChevronRightIcon />
            </span>
          </ListItem>
          <ListDivider sx={{ background: '#EBEBEF' }} inset="startContent" />
          <ListItem onClick={() => navigate('terms')} sx={{ height: 44 }}>
            <ListItemDecorator>
              <TickIcon style={{ width: 24, height: 24 }} />
            </ListItemDecorator>
            <span className={styles.profileListItemTile}>
              Пользовательское соглашение
              <ChevronRightIcon />
            </span>
          </ListItem>
          {/* <ListDivider sx={{ background: '#EBEBEF' }} inset="startContent" />
          <ListItem onClick={() => navigate('/settings')} sx={{ height: 44 }}>
            <ListItemDecorator>
              <ShieldIcon style={{ width: 24, height: 24 }} />
            </ListItemDecorator>
            <span className={styles.profileListItemTile}>
              Договор оферты
              <ChevronRightIcon />
            </span>
          </ListItem> */}
        </>
      )}
    </List>
  )
}
