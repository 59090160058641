/* eslint-disable no-restricted-globals */
import { useEffect, useMemo, useRef, useState } from 'react'
import { ProfileCompact, ProfileVariant } from '../../models/Profile'
import { addCategoryToProfiles, getProfiles } from '../../clients/AdminClient'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styles from './AdminProfilesPage.module.sass'
import {
  observeWindowOffset,
  PartialKeys,
  useWindowVirtualizer,
  VirtualItem,
  VirtualizerOptions,
} from '@tanstack/react-virtual'
import { Checkbox, Chip, Input } from '@mui/joy'
import { CategoryForm } from './AdminProfileViewPage'

export const ProfileCompactAdminListItem = ({
  isSelected,
  toggleSelect,
  profile,
}: {
  profile: ProfileCompact
  isSelected?: boolean
  toggleSelect?: () => void
}) => {
  const navigate = useNavigate()

  return (
    <div className={styles.profileTile} key={profile.id}>
      <div className={styles.truncatedLabel}>
        {toggleSelect && <Checkbox checked={isSelected} onChange={toggleSelect} />}
        <div onClick={() => navigate(`/admin/profile/${profile.id}`)}>{profile.position}</div>
      </div>
      <div>{profile.variant}</div>
      <div>{profile.createdAt}</div>
      <div>
        {profile.categoryId === null ? (
          <Chip color="danger" variant="solid" size="sm">
            Без категории
          </Chip>
        ) : (
          <Chip color="primary" variant="soft" size="sm">
            С категорией
          </Chip>
        )}
      </div>
    </div>
  )
}

const VirtualList = (props: {
  options: PartialKeys<
    VirtualizerOptions<Window, HTMLDivElement>,
    'getScrollElement' | 'observeElementRect' | 'observeElementOffset' | 'scrollToFn'
  >
  children: (items: VirtualItem) => React.ReactNode
}) => {
  const listRef = useRef<HTMLDivElement | null>(null)

  const virtualizer = useWindowVirtualizer({
    initialOffset: history.state.offset ?? 0,
    observeElementOffset: (instance, cb) => {
      return observeWindowOffset(instance, (offset, isScrolling) => {
        console.log('observer', offset)
        if (offset) {
          history.replaceState(
            {
              offset,
            },
            document.title,
            window.location.href,
          )
        }

        cb(offset, isScrolling)
      })
    },
    ...props.options,
  })

  const items = virtualizer.getVirtualItems()

  return (
    <div className={styles.profilesWrapper} ref={listRef}>
      <div
        className={styles.profilesList}
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {items.map(item => {
          return (
            <div
              key={props.options.getItemKey?.(item.index) as any}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${item.size}px`,
                transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
              }}
            >
              {props.children(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const AdminProfilesPage = () => {
  const [profiles, setProfiles] = useState<ProfileCompact[]>()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const showUncategorizedOnly = searchParams.get('showUncategorizedOnly') === 'true'

  const [counter, setCounter] = useState(0)

  const [selectedProfilesIds, setSelectedProfilesIds] = useState<Set<number>>(new Set())

  const isProfileSelected = (profileId: number) => selectedProfilesIds.has(profileId)

  const toggleProfileSelection = (profileId: number) => {
    setSelectedProfilesIds(prev => {
      const newSet = new Set(prev)
      if (newSet.has(profileId)) {
        newSet.delete(profileId)
      } else {
        newSet.add(profileId)
      }
      return newSet
    })
  }

  useEffect(() => {
    setSelectedProfilesIds(new Set())
    getProfiles({ showUncategorizedOnly }).then(({ data }) => setProfiles(data))
  }, [showUncategorizedOnly, setSelectedProfilesIds, counter])

  const lastDayProfiles = profiles?.filter(
    p => new Date(p.createdAt) > new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  )

  const results = useMemo(() => {
    return profiles?.filter(p => p.position.toLowerCase().includes(query.toLowerCase()))
  }, [profiles, query])

  const [isProcessingCategories, setProcessingCategories] = useState(false)

  return (
    <>
      {lastDayProfiles && profiles && (
        <div className={styles.lastDayProfiles}>
          <div>
            День: {lastDayProfiles.length} ({lastDayProfiles.filter(p => p.variant === ProfileVariant.Job).length} J /{' '}
            {lastDayProfiles.filter(p => p.variant === ProfileVariant.Resume).length} R)
          </div>
          <div>
            Всего: {profiles.length} ({profiles.filter(p => p.variant === ProfileVariant.Job).length} J /{' '}
            {profiles.filter(p => p.variant === ProfileVariant.Resume).length} R)
          </div>
          <div>
            <Input
              placeholder="Поиск"
              value={query}
              onChange={e =>
                setSearchParams(
                  { showUncategorizedOnly: String(showUncategorizedOnly), query: e.target.value },
                  { replace: true },
                )
              }
            />
          </div>
          <div>
            <Checkbox
              defaultChecked={showUncategorizedOnly}
              onChange={e =>
                setSearchParams(
                  { query, showUncategorizedOnly: String(e.target.checked) },
                  {
                    replace: true,
                  },
                )
              }
              label="Только без категорий"
            />
          </div>
          {results && (
            <div>
              <Checkbox
                disableIcon
                onChange={() => {
                  if (selectedProfilesIds.size > 0) {
                    setSelectedProfilesIds(new Set())
                  } else {
                    setSelectedProfilesIds(new Set(results.map(p => p.id)))
                  }
                }}
                style={{ padding: '4px 8px' }}
                variant={selectedProfilesIds.size > 0 ? 'solid' : 'outlined'}
                color="primary"
                label={selectedProfilesIds.size > 0 ? 'Очистить выбор' : 'Выделить все'}
              />
            </div>
          )}
          {results && selectedProfilesIds.size > 0 && (
            <CategoryForm
              categoryId={undefined}
              onFormSent={categoryName => {
                setProcessingCategories(true)
                addCategoryToProfiles(categoryName, [...selectedProfilesIds]).then(() => {
                  setProcessingCategories(false)
                  setSelectedProfilesIds(new Set())
                  setCounter(prev => prev + 1)
                })
              }}
              disabled={isProcessingCategories}
            />
          )}
        </div>
      )}
      {results?.length !== undefined && (
        <VirtualList
          options={{
            estimateSize: () => 150,
            count: results.length,
            overscan: 5,
            gap: 8,
            getItemKey: index => results.at(index)?.id ?? index,
          }}
        >
          {item => {
            const profile = results[item.index]

            if (!profile) {
              return null
            }

            return (
              <ProfileCompactAdminListItem
                isSelected={isProfileSelected(profile.id)}
                toggleSelect={() => {
                  toggleProfileSelection(profile.id)
                }}
                profile={profile}
              />
            )
          }}
        </VirtualList>
      )}
    </>
  )
}
