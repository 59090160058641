import { useEffect, useState } from 'react'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { ProfileCompact, ProfileVariant } from '../../models/Profile'
import { getMyProfiles } from '../../clients/UserClient'
import { ProfilesList, SettingsList } from '../../ui/ProfilesList/ProfilesList'
import styles from './MainPage.module.sass'
import { LinearProgress } from '@mui/joy'
import { useAppMounting, useAppState } from '../../ui/AppState'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { StepperBottomMenu } from '../../ui/Stepper/StepperBottomMenu'
import { Button } from '../../ui/Button/Button'
import { XIcon } from '../../icons/XIcon'

export const MainPage = () => {
  const [profiles, setProfiles] = useState<ProfileCompact[]>()
  const [isArchivedOnly, setArchivedOnly] = useState(false)

  const { startPayload } = useAppState()
  const reload = useAppMounting()

  const initData = (() => {
    if (startPayload && startPayload.key === 'publicProfile') {
      return startPayload
    }
    return null
  })()

  useEffect(() => {
    getMyProfiles()
      .then(({ data }) => setProfiles(data))
      .catch(e => console.log(e))
  }, [setProfiles])

  const navigate = useNavigate()

  const targetVariant =
    initData &&
    { [ProfileVariant.Job]: ProfileVariant.Resume, [ProfileVariant.Resume]: ProfileVariant.Job }[
      initData.publicProfileVariant
    ]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  if (profiles && initData) {
    const results = profiles.filter(p => !p.isArchived && p.variant === targetVariant)

    const onCancel = () => {
      sessionStorage.removeItem('startPayload')
      setSearchParams({}, { replace: true })
      reload()
    }

    return (
      <PageLayout title="Relevants">
        <div style={{ textAlign: 'end', paddingBottom: '4px', marginTop: '-10px' }}>
          <XIcon onClick={onCancel} />
        </div>
        <div className={styles.sectionsWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span>
                Выберите{' '}
                {initData.publicProfileVariant === ProfileVariant.Job
                  ? 'свое резюме, с помощью которого'
                  : 'свою вакансию, с помощью которой'}{' '}
                вы хотите откликнуться на{' '}
                {initData.publicProfileVariant === ProfileVariant.Job ? 'эту вакансию' : 'это резюме'}.
              </span>
            </div>
            {results.length === 0 && (
              <div>
                <span>
                  Для отклика на {initData.publicProfileVariant === ProfileVariant.Job ? 'эту вакансию' : 'это резюме'}{' '}
                  вам необходим профиль. Создайте его за несколько минут.
                </span>
              </div>
            )}
            <ProfilesList
              onListItemClick={p => {
                navigate('/profile/' + p.id + '/public/' + initData.publicProfileDomain)
              }}
              onAddProfileClick={() => {
                navigate('/create-profile/' + targetVariant)
              }}
              profiles={results}
            />
          </div>
        </div>
        <StepperBottomMenu anyDevice className={styles.stepperBottomMenu}>
          <Button color="neutral" style={{ height: 48 }} fullWidth onClick={onCancel} variant="soft">
            Отмена
          </Button>
        </StepperBottomMenu>
      </PageLayout>
    )
  }

  return (
    <PageLayout title="Relevants">
      {!profiles ? (
        <LinearProgress />
      ) : (
        <div className={styles.sectionsWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span>Аккаунт</span>
            </div>
            <SettingsList isOpen={false} />
          </div>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span className={isArchivedOnly ? styles.notActive : undefined} onClick={() => setArchivedOnly(false)}>
                Профили
              </span>
              <span className={!isArchivedOnly ? styles.notActive : undefined} onClick={() => setArchivedOnly(true)}>
                Архив
              </span>
            </div>
            <ProfilesList profiles={profiles.filter(p => (isArchivedOnly ? p.isArchived : !p.isArchived))} />
          </div>
        </div>
      )}
    </PageLayout>
  )
}
