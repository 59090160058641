import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  addProfileCategory,
  addProfilePositionName,
  addProfileToSearch,
  getProfileCategories,
  getProfileDetails,
  getProfilePositionNames,
  makeSearchAsProfile,
  post,
  removeProfileFromSearch,
  restoreSuspendedProfile,
  suspendProfile,
} from '../../clients/AdminClient'
import { ProfileCompact, ProfileExplicit, ProfileVariant } from '../../models/Profile'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { Autocomplete, Button, Chip, Divider, LinearProgress, Link } from '@mui/joy'
import { Card } from '../../ui/Card/Card'
import styles from './AdminProfileViewPage.module.sass'
import { useToast } from '../../ui/Toast/Toast'

const DeleteButton = ({
  onDelete,
  isSuspended,
  onRestore,
  disabled,
  label,
  revertLabel,
}: {
  onDelete: () => void
  onRestore: () => void
  isSuspended: boolean
  disabled?: boolean
  label: string
  revertLabel: string
}) => {
  const [isActive, setActive] = useState(false)

  if (isSuspended) {
    return (
      <Button variant="soft" color="neutral" onClick={onRestore} disabled={disabled}>
        {revertLabel}
      </Button>
    )
  }

  return (
    <Button
      disabled={disabled}
      color="danger"
      variant={isActive ? 'solid' : 'soft'}
      onClick={
        isActive
          ? () => {
              onDelete()
              setActive(false)
            }
          : () => setActive(true)
      }
    >
      {isActive ? '!!! Подтвердить удаление !!!' : label}
    </Button>
  )
}

const PostButton = ({ profileId }: { profileId: number }) => {
  const [isActive, setActive] = useState(false)
  const { showToast } = useToast()
  return (
    <Button
      color="danger"
      variant={isActive ? 'solid' : 'soft'}
      onClick={
        isActive
          ? () => {
              post(profileId)
                .then(() => {
                  showToast({
                    title: 'Выложено',
                  })
                })
                .catch(() => {
                  showToast({
                    title: 'Ошибка',
                    variant: 'error',
                  })
                })
              setActive(false)
            }
          : () => setActive(true)
      }
    >
      {isActive ? '!!! Подтвердить публикацию !!!' : 'Опубликовать в канал'}
    </Button>
  )
}

export const CategoryForm = ({
  categoryId,
  onFormSent,
  disabled,
}: {
  categoryId?: number
  onFormSent: (c: string) => void
  disabled: boolean
}) => {
  const [categories, setCategories] = useState<{ id: number; name: string }[]>()
  const [value, setValue] = useState('')

  useEffect(() => {
    getProfileCategories().then(({ data }) => {
      setCategories(data)
      setValue(data.find(c => c.id === categoryId)?.name ?? '')
    })
  }, [categoryId])

  return (
    <div className={styles.formWrapper}>
      <Autocomplete
        sx={{ flex: 1 }}
        options={categories?.map(c => c.name) ?? []}
        placeholder="Категория"
        value={value}
        onInputChange={(_, value) => {
          setValue(value)
        }}
        readOnly={disabled}
      />
      <Button color="primary" variant="soft" onClick={() => onFormSent(value)} disabled={disabled || !value}>
        OK
      </Button>
    </div>
  )
}

const PositionForm = ({
  positionNameId,
  onFormSent,
  disabled,
}: {
  positionNameId?: number
  onFormSent: (c: string) => void
  disabled: boolean
}) => {
  const [positionNames, setPositionName] = useState<{ id: number; name: string }[]>()
  const [value, setValue] = useState('')

  useEffect(() => {
    getProfilePositionNames().then(({ data }) => {
      setPositionName(data)
      setValue(data.find(c => c.id === positionNameId)?.name ?? '')
    })
  }, [positionNameId])

  return (
    <div className={styles.formWrapper}>
      <Autocomplete
        sx={{ flex: 1 }}
        options={positionNames?.map(c => c.name) ?? []}
        value={value}
        onInputChange={(_, value) => {
          setValue(value)
        }}
        readOnly={disabled}
        placeholder="Чистая позиция"
      />
      <Button color="primary" variant="soft" onClick={() => onFormSent(value)} disabled={disabled || !value}>
        OK
      </Button>
    </div>
  )
}

export const AdminProfileViewPage = () => {
  const params = useParams()
  const profileId = Number(params.id)
  const [profile, setProfile] = useState<ProfileExplicit>()
  const [counter, setCounter] = useState(0)
  const [loading, setLoading] = useState(false)

  const [searchResults, setSearchResults] = useState<(ProfileCompact & { score: number })[] | undefined | null>()

  useEffect(() => {
    getProfileDetails(profileId).then(({ data }) => setProfile(data))
  }, [profileId, counter])

  return (
    <>
      {profile ? (
        <>
          <ProfileDetails profile={profile} />
          <Card title="Инфо">
            <div>user_id: {String(profile.userId)}</div>
            <div>ip: {profile.ip}</div>
            <div>is_suspended: {String(profile.isSuspended)}</div>
            {profile.isSuspended && <div>suspension_reason: {String(profile.suspensionReason)}</div>}
            {profile.isSuspended && <div>suspended_at: {String(profile.suspendedAt)}</div>}
            <div>is_suspicious: {String(profile.isSuspicious)}</div>
            <div>is_archived: {String(profile.isArchived)}</div>
            {profile.isArchived && <div>archived_at: {String(profile.archivedAt)}</div>}
            <div>telegram_id: {String(profile.telegramId)}</div>
            <div>created_at: {String(profile.createdAt)}</div>
            <div>В индексе: {String(profile.isInSearchIndex)}</div>
            <div>СВ/М/ВЛ/ИЛ/СК: 0 / 0 / 0 / 0 / 0</div>
          </Card>
          <div className={styles.actionsWrapper}>
            {loading && <LinearProgress />}
            <DeleteButton
              label="Удалить явно"
              revertLabel="Разбан"
              disabled={loading || profile.isSuspicious}
              onDelete={() => {
                setLoading(true)
                suspendProfile(profile.id, false)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              onRestore={() => {
                setLoading(true)
                restoreSuspendedProfile(profile.id, false)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              isSuspended={profile.isSuspended}
            />
            <DeleteButton
              label="Теневой бан"
              revertLabel="Теневой разбан"
              disabled={loading || profile.isSuspended}
              onDelete={() => {
                setLoading(true)
                suspendProfile(profile.id, true)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              onRestore={() => {
                setLoading(true)
                restoreSuspendedProfile(profile.id, true)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              isSuspended={profile.isSuspicious}
            />
            <DeleteButton
              label="Выкинуть из поиска"
              revertLabel="Вернуть в поиск"
              disabled={loading || profile.isSuspended || profile.isArchived || profile.isSuspicious}
              onDelete={() => {
                setLoading(true)
                removeProfileFromSearch(profile.id)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              onRestore={() => {
                setLoading(true)
                addProfileToSearch(profile.id)
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
              isSuspended={!profile.isInSearchIndex}
            />
            <PostButton profileId={profile.id} />
          </div>
          <div>
            <CategoryForm
              disabled={loading}
              categoryId={profile.categoryId}
              onFormSent={category => {
                setLoading(true)
                addProfileCategory({ profileId: profile.id, category })
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
            />
            <PositionForm
              disabled={loading}
              positionNameId={profile.positionNameId}
              onFormSent={name => {
                setLoading(true)
                addProfilePositionName({ profileId: profile.id, name })
                  .then(() => {
                    setCounter(prev => prev + 1)
                  })
                  .finally(() => setLoading(false))
              }}
            />
          </div>
          <div className={styles.searchResults}>
            <Button
              onClick={() => {
                setSearchResults(undefined)
                makeSearchAsProfile(profile.id).then(data => setSearchResults(data.data))
              }}
              style={{ width: '100%' }}
            >
              Получить результаты
            </Button>
            {searchResults && (
              <>
                <Divider />
                <div className={styles.searchResultsList}>
                  {profile.variant === ProfileVariant.Job ? 'Вакансии' : 'Резюме'}:
                  {searchResults.map(r => (
                    <div key={r.id} className={styles.searchResultsListItem}>
                      <Link component="a" href={`/admin/profile/${r.id}`}>
                        {r.position} (id{r.id})
                      </Link>
                      <div className={styles.chipsRow}>
                        <Chip size="sm" variant="soft" color="primary">
                          {r.score}
                        </Chip>
                        {r.isParsed && (
                          <Chip size="sm" variant="soft" color="warning">
                            Внешний
                          </Chip>
                        )}
                        {r.categoryId === profile.categoryId && profile.categoryId !== null && (
                          <Chip size="sm" variant="soft" color="success">
                            Одна категория
                          </Chip>
                        )}
                      </div>
                      <div>
                        <b>Описание: </b>
                        {r.description}
                      </div>
                      <div>
                        <b>Ищет: </b>
                        {r.lookingForDescription}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  )
}
