import { createContext, useCallback, useMemo, useState } from 'react'
import { PremiumAction, User } from '../models/User'
import { useSafeContext } from './hooks/useSafeContext'
import { getUserDetails, trackPremiumStats } from '../clients/UserClient'
import { PremiumIntro } from './PremiumIntro/PremiumIntro'
import { ProfileVariant } from '../models/Profile'
import { useSearchParams } from 'react-router-dom'

type StartPayload = {
  key: 'publicProfile'
  publicProfileDomain: string
  publicProfileVariant: ProfileVariant
}

interface AppStateType {
  user: User
  setState?: React.Dispatch<React.SetStateAction<AppStateType>>
  trackPremiumStats: (action: PremiumAction) => void
  closePremiumIntro: () => void
  isPremiumIntroOpen: boolean
  refetchUser: () => Promise<void | null>
  startPayload?: StartPayload
}

const AppStateContext = createContext<AppStateType | null>(null)

interface AppStateProps {
  children: React.ReactNode
  user: User
}
export const AppState = ({ children, user }: AppStateProps) => {
  const [params] = useSearchParams()
  const startPayloadString = params.get('tgWebAppStartParam')
  const sessionStorageData = sessionStorage.getItem('startPayload')

  const startPayload = useMemo(() => {
    if (sessionStorageData) {
      return JSON.parse(sessionStorageData) as StartPayload
    }

    if (!startPayloadString) {
      return undefined
    }

    const [key, value] = startPayloadString.split('-')

    if (!key || !value) {
      return undefined
    }

    if (key === 'publicProfile') {
      const parts = value.split('_')
      if (parts.length !== 2) {
        return undefined
      }

      const [publicProfileDomain, publicProfileVariant] = parts

      const result = {
        key: key as 'publicProfile',
        publicProfileDomain,
        publicProfileVariant: publicProfileVariant as ProfileVariant,
      }

      sessionStorage.setItem('startPayload', JSON.stringify(result))

      return result
    }

    return undefined
  }, [startPayloadString, sessionStorageData])

  const [isPremiumIntroOpen, setPremiumIntroOpen] = useState(false)
  const [state, setState] = useState<AppStateType>({
    user,
    refetchUser: () => Promise.resolve(null),
    isPremiumIntroOpen,
    trackPremiumStats: async action => {
      setPremiumIntroOpen(true)
      await trackPremiumStats(action)
    },
    closePremiumIntro: () => setPremiumIntroOpen(false),
    startPayload,
  })

  const refetchUser = useCallback(
    () =>
      getUserDetails()
        .then(({ data }) => setState(prev => ({ ...prev, user: data })))
        .catch(() => {
          // todo
        }),

    [setState],
  )

  return (
    <AppStateContext.Provider value={{ ...state, setState, refetchUser, startPayload }}>
      {isPremiumIntroOpen && <PremiumIntro />}
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  const { setState, ...appState } = useSafeContext(AppStateContext)
  // todo some further logic here

  return {
    ...appState,
  }
}

export const AppMountingContext = createContext<{ reloadApp: () => void } | null>(null)

export const AppMountingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [counter, setCounter] = useState(0)
  const reloadApp = useCallback(() => {
    setCounter(prev => prev + 1)
  }, [setCounter])

  return (
    <AppMountingContext.Provider value={{ reloadApp }} key={counter}>
      {children}
    </AppMountingContext.Provider>
  )
}

export const useAppMounting = () => {
  const { reloadApp } = useSafeContext(AppMountingContext)

  return reloadApp
}
