import { FormHelperText, Modal, ModalDialog, Typography } from '@mui/joy'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { useCreateProfileState } from './useCreateProfileState'
import { ProfileVariant } from '../../models/Profile'
import { Stepper } from '../../ui/Stepper/Stepper'
import { StepperBottomMenu } from '../../ui/Stepper/StepperBottomMenu'
import { Button } from '../../ui/Button/Button'
import { createContext, forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { ProfileBase } from '../../models/Profile'
import { useAppMounting, useAppState } from '../../ui/AppState'
import { createProfileSteps } from './createProfileSteps'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LogoBlackIcon } from '../../icons/LogoIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { canCreateProfile as canCreateProfileReq } from '../../clients/UserClient'
import { useToast } from '../../ui/Toast/Toast'
import { createProfile, trackCreateProfileActivity } from '../../clients/ProfileClient'
import { isIOS } from '../../tools/ClientUtil'
import { HttpStatusCode } from 'axios'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { MainButtonContext } from '../../ui/MainButtonContext'
import styles from './CreateProfilePage.module.sass'
import { v4 as uuid } from 'uuid'
import { FormButtonContextProvider } from '../../ui/FormButtonContext'
import { useLatestRef } from '../../hooks/useLatestRef'

export const getDefaultPicture = () => {
  const pictures = ['uploads/default/default1.png', 'uploads/default/default2.png', 'uploads/default/default3.png']
  return pictures[Math.floor(Math.random() * pictures.length)]
}

export const NavigationTile = forwardRef(({ title, onClick }: { title: string; onClick: () => void }, ref: any) => {
  return (
    <div className={styles.navigationTile} onClick={onClick} ref={ref}>
      <div>
        <div></div>
        <span>{title}</span>
      </div>
      <div>
        <ChevronRightIcon />
      </div>
    </div>
  )
})

export interface CreateProfileContextState {
  state: Partial<ProfileBase>
  onChange: <K extends keyof ProfileBase>(name: K) => (value: ProfileBase[K]) => void
  errors?: Partial<Record<keyof ProfileBase, string>>
  next: () => void
  back: () => void
  resetProfile: () => void
  goToStep: (step: number) => void
  onClose: () => void
  isEditPage?: boolean
  isOnboardingPage?: boolean
  setRef?: (key: keyof ProfileBase) => (el: HTMLElement | null) => void
}

export const CreateProfileContext = createContext<CreateProfileContextState | null>(null)

export const CreateProfilePage = () => {
  const [currentStep, setCurrentStep] = useState<number>()
  const { variant } = useParams()
  const navigate = useNavigate()

  const [canCreateProfile, setCanCreateProfile] = useState<{
    canCreateResumeProfile: boolean
    canCreateJobProfile: boolean
  }>()

  const { user, startPayload } = useAppState()
  const { showToast } = useToast()

  const { hide, show, showProgress, hideProgress, enable, setParams, onClick, offClick } =
    useSafeContext(MainButtonContext)

  const reloadApp = useAppMounting()

  const { errors, profile, onChange, isInitialized, resetProfile } = useCreateProfileState({
    currentStep,
    setCurrentStep,
    isPersistent: true,
  })

  useEffect(() => {
    if (canCreateProfile && variant) {
      if (
        (variant === ProfileVariant.Job && !canCreateProfile.canCreateJobProfile) ||
        (variant === ProfileVariant.Resume && !canCreateProfile.canCreateResumeProfile)
      ) {
        showToast({
          title: 'Нельзя создать профиль',
          description: `Одновременно вы можете иметь только 1 профиль-${
            (variant as ProfileVariant) === ProfileVariant.Job ? 'вакансию' : 'резюме'
          }. Сначала удалите предыдущий.`,
          variant: 'error',
        })

        return navigate('/')
      }
      setCurrentStep(0)
      onChange('logToken')(uuid())
      onChange('variant')(variant as ProfileVariant)
      onChange('profilePicture')(getDefaultPicture())
    }
  }, [variant, showToast, onChange, setCurrentStep, canCreateProfile, navigate])

  const targetSteps = useMemo(
    () => createProfileSteps(profile.variant?.toLowerCase() ?? ('job' as any)),
    [profile.variant],
  )

  const stepsCount = targetSteps.length

  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

  useEffect(() => {
    if (currentStep !== stepsCount - 1) {
      document.body.style.background = '#fff'
    } else {
      document.body.style.background = '#f7f7f8'
    }
  }, [currentStep, stepsCount])

  useEffect(() => {
    return () => {
      document.body.style.background = '#f7f7f8'
    }
  }, [])

  const contextValue: CreateProfileContextState = {
    back: () => setCurrentStep(step => step && (step === 0 ? 0 : step - 1)),
    errors,
    goToStep: setCurrentStep,
    next: () =>
      setCurrentStep(step => (step !== undefined ? (step === targetSteps.length - 1 ? step : step + 1) : step)),
    onChange,
    resetProfile,
    state: profile,
    onClose: () => setConfirmDialogOpen(true),
  }

  useEffect(() => {
    canCreateProfileReq().then(({ data }) => {
      setCanCreateProfile(data)
    })
  }, [])

  useEffect(() => {
    if (currentStep !== undefined && currentStep !== null && profile.variant) {
      if (isIOS()) {
        show()
      }
      trackCreateProfileActivity({
        step: currentStep,
        profileVariant: profile.variant,
        logToken: profile.logToken!,
      })
    } else {
      if (isIOS()) {
        hide()
      }
    }
  }, [currentStep, profile.variant, show, hide, profile.logToken])

  useEffect(() => {
    document.body.scrollTop = 0
  }, [currentStep])

  const isLastStep = currentStep === stepsCount - 1

  const buttonParams = useMemo(
    () => ({
      text: isLastStep ? 'Создать профиль' : 'Продолжить',
      text_color: '#054DA7',
      color: '#DDF1FF',
    }),
    [isLastStep],
  )

  const profileRef = useLatestRef(profile)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  const callBack = useCallback(() => {
    if (isLastStep) {
      showProgress(false)
      createProfile(profileRef.current)
        .then(({ data }) => {
          resetProfile()

          if (startPayload && startPayload.key === 'publicProfile') {
            sessionStorage.removeItem('startPayload')
            setSearchParams({}, { replace: true })
            navigate(`/profile/${data.id}/public/${startPayload.publicProfileDomain}`)
          } else {
            navigate(`/profile/${data.id}`)
          }

          if (user.everCreatedProfilesCount === 0) {
            reloadApp()
          }
        })
        .catch(({ response }) => {
          if (response.status === HttpStatusCode.BadRequest) {
            showToast({
              title: 'Ошибка',
              description: 'Некоторые поля заполнены некорректно',
              variant: 'error',
            })
          } else {
            showToast({
              title: 'Ошибка',
              description: response?.data?.details?.ru ?? 'Что-то пошло не так',
              variant: 'error',
            })
          }
        })
        .finally(() => {
          hideProgress()
          enable()
        })
    } else {
      setCurrentStep(step => (step !== undefined ? (step === stepsCount - 1 ? step : step + 1) : step))
    }
  }, [
    enable,
    hideProgress,
    isLastStep,
    navigate,
    profileRef,
    reloadApp,
    resetProfile,
    showProgress,
    showToast,
    stepsCount,
    user.everCreatedProfilesCount,
    setSearchParams,
    startPayload,
  ])

  const resetButtonHandler = useCallback(() => {
    offClick(callBack)
  }, [callBack, offClick])

  const resetToDefaultButton = useCallback(() => {
    if (currentStep !== undefined && currentStep !== null && profile.variant && isIOS()) {
      setParams(buttonParams)
      onClick(callBack)
    }
  }, [currentStep, profile.variant, setParams, buttonParams, onClick, callBack])

  useEffect(() => {
    resetToDefaultButton()
    return () => resetButtonHandler()
  }, [resetToDefaultButton, resetButtonHandler])

  useEffect(() => {
    return () => {
      enable()
      hide()
    }
  }, [enable, hide])

  return (
    <FormButtonContextProvider removeHandler={resetButtonHandler} resetToDefault={resetToDefaultButton}>
      {profile.variant ? (
        <CreateProfileContext.Provider value={contextValue}>
          <Stepper
            setStep={setCurrentStep}
            steps={targetSteps}
            currentStep={currentStep ?? 0}
            isGrayBackground={currentStep === stepsCount - 1}
            onClose={() => setConfirmDialogOpen(true)}
          />
          {isConfirmDialogOpen && (
            <Modal open={isConfirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
              <ModalDialog variant="outlined" role="alertdialog">
                <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                  <Typography level="h4">Вы уверены?</Typography>
                  <Typography textColor="text.tertiary">
                    Вы действительно хотите завершить создание профиля? Это удалит все введенные данные.
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => setConfirmDialogOpen(false)} color="neutral" variant="plain">
                      Отмена
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => {
                        if (startPayload && startPayload.key === 'publicProfile') {
                          sessionStorage.removeItem('startPayload')
                          setSearchParams({}, { replace: true })
                          navigate('/')
                        }
                        resetProfile()
                        setConfirmDialogOpen(false)
                      }}
                    >
                      Удалить
                    </Button>
                  </div>
                </div>
              </ModalDialog>
            </Modal>
          )}
        </CreateProfileContext.Provider>
      ) : (
        <>
          {isInitialized && canCreateProfile && (
            <PageLayout className={styles.firstScreen} title="Создание нового профиля">
              <div className={styles.startScreenWrapper}>
                <div className={styles.logoWrapper}>
                  <LogoBlackIcon style={{ width: 54, height: 54 }} />
                  <Typography level="h3">Relevants</Typography>
                </div>
                <div>
                  <Typography level="h4">Выберите тип профиля</Typography>
                  <FormHelperText>В дальнейшем вы сможете создавать другие профили</FormHelperText>
                </div>
                <div className={styles.tilesWrapper}>
                  <NavigationTile
                    onClick={() => {
                      if (!canCreateProfile.canCreateResumeProfile) {
                        return showToast({
                          title: 'Нельзя создать профиль',
                          description:
                            'Одновременно вы можете иметь только 1 профиль-резюме. Сначала удалите предыдущий.',
                          variant: 'error',
                        })
                      }
                      setCurrentStep(0)
                      onChange('logToken')(uuid())
                      onChange('variant')(ProfileVariant.Resume)
                      onChange('profilePicture')(getDefaultPicture())
                    }}
                    title="Я хочу работать"
                  />
                  <NavigationTile
                    onClick={() => {
                      if (!canCreateProfile.canCreateJobProfile) {
                        return showToast({
                          title: 'Нельзя создать профиль',
                          description:
                            'Одновременно вы можете иметь только 1 профиль-вакансию. Сначала удалите предыдущий.',
                          variant: 'error',
                        })
                      }
                      setCurrentStep(0)
                      onChange('logToken')(uuid())
                      onChange('variant')(ProfileVariant.Job)
                      onChange('profilePicture')(getDefaultPicture())
                    }}
                    title="Я хочу нанять"
                  />
                  {/* <FormHelperText sx={{ marginTop: '24px' }}>
                    Премиум-подписка позволяет создавать несколько профилей одного типа одновременно, а так же дает
                    доступ к уникальным функциям
                  </FormHelperText>
                  <Button
                    color="primary"
                    sx={{
                      marginTop: '8px',
                    }}
                    onClick={() => trackPremiumStats('CreateMoreProfiles')}
                    startDecorator={<PremiumStar />}
                  >
                    Подробнее о Премиум
                  </Button> */}
                </div>
              </div>
              {/* {user.everCreatedProfilesCount > 0 && ( */}
              <StepperBottomMenu anyDevice>
                <Button color="neutral" style={{ height: 48 }} fullWidth onClick={() => navigate('/')}>
                  Отмена
                </Button>
              </StepperBottomMenu>
              {/* )} */}
            </PageLayout>
          )}
        </>
      )}
    </FormButtonContextProvider>
  )
}
