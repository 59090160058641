import axios from 'axios'
import config from '../config'
import {
  Profile,
  ProfileBase,
  ProfileCategory,
  ProfileCity,
  ProfileDayStats,
  ProfileQueueItem,
  ProfileVariant,
  ReportReason,
} from '../models/Profile'

const profileUrl = (path: string) => `${config.API_URL}/profile/${path}`

export const findCity = (cityName: string) => axios.post<ProfileCity[]>(profileUrl('findCity'), { cityName })

export const createProfile = (profile: ProfileBase) => axios.post<{ id: number }>(profileUrl('create'), profile)

export const getProfileWeeklyStats = (profileId: number) =>
  axios.get<ProfileDayStats[]>(profileUrl(`${profileId}/stats`))

export const getProfilesLikesCount = () => axios.get<Record<string, number>>(profileUrl('likes-count'))

export const getProfileIncomingLikes = (profileId: number) =>
  axios.get<ProfileQueueItem[]>(profileUrl(`${profileId}/likes`))

export const getProfileMatches = (profileId: number) =>
  axios.get<ProfileQueueItem[]>(profileUrl(`${profileId}/matches`))

export const markAsArchived = (profileId: number) => axios.post(profileUrl('mark-as-archived'), { profileId })

export const sendContact = (profileId: number, targetProfileId: number) =>
  axios.post(profileUrl('send-contact'), { profileId, targetProfileId })

export const getProfileDetails = (profileId: number, targetProfileId: number) =>
  axios.get<ProfileQueueItem>(profileUrl(`${profileId}/${targetProfileId}`))

export const reportProfile = (report: {
  profileId: number
  targetProfileId: number
  comment?: string
  reason: ReportReason
}) => axios.post(profileUrl('report'), { ...report })

export const trackCreateProfileActivity = (props: { step: number; profileVariant: ProfileVariant; logToken: string }) =>
  axios.post(profileUrl(`track`), props)

export const getCategories = () => axios.get<ProfileCategory[]>(profileUrl(`categories`))

export const generateTags = (profile: Partial<Profile>) => axios.post<string[]>(profileUrl(`generate-tags`), profile)

export const editProfile = (profile: Partial<Profile>) => axios.post<string[]>(profileUrl(`edit`), profile)

export const getPublicProfile = (nickname: string) => axios.get<Profile>(profileUrl(`public/${nickname}`))
export const togglePublicProfile = (id: number) => axios.post<{ nickname: string }>(profileUrl(`public/toggle/${id}`))
